<template>
  <div id="app">
    <div class="Menu">
      <router-link to="/">Enigmes</router-link>
      <router-link to="/is">IS</router-link>
      <router-link to="/mesures">Mesures</router-link>
      <router-link to="/morse">Morse Decoder</router-link>
    </div>
    <router-view :chouette="chouette"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      chouette: {},
    };
  },
  mounted() {
    fetch("chouette.json")
      .then((r) => r.json())
      .then((json) => {
        this.chouette = json;
      });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.Menu {
  font-size: 1.6em;
  margin-bottom: 20px;
}
.Menu * {
  margin-right: 0.5em;
}
@media screen and (max-width: 500px) {
  #app {
    font-size: 0.7em;
  }
}
</style>
