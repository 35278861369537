I
<template>
	<div>
		<p>{{ data.date }}</p>
		<h2 class="ligne" v-for="(ligne, i) of lignes" :key="i">
			{{ ligne }}
		</h2>
	</div>
</template>

<script>
export default {
	name: "Is",
	props: ["data"],
	computed: {
		lignes() {
			return this.data.texte.split("\r\n");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}

.ligne {
	margin: 0;
}
</style>
