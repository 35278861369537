<template>
  <div>
    <table>
      <tr>
        <th>mesure</th>
        <th>560606</th>
        <th>1969,697</th>
        <th>1959,687</th>
      </tr>
      <mesure :key="mesure" :mesure="mesure" v-for="mesure of mesures"/>
    </table>
  </div>
</template>

<script>
import Mesure from './Mesure.vue'
export default {
  components: {
    Mesure,
  },
  data() {
    return {
      v1: 1969.697,
      v2: 1959.687,

      min: 0.0001,
      max: 3,
      step: 0.0001,

      mesures: [],
    }
  },
  mounted() {
    for (let i = this.min; i < this.max; i += this.step) {
      let d = this.v1 * i;
      let f = Math.round(d);
      if (Math.abs(f - d) < 0.001) {
        this.mesures.push(i.toFixed(4));
      }
    }
  },
};
</script>

<style>
table {
  border-collapse:collapse;
}
table td {
  padding: 5px;
  border: 1px solid #aaa
}
</style>