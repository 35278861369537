import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

Vue.use(VueRouter);

Vue.config.productionTip = false;

import Home from "./components/Home.vue";
import Iss from "./components/Iss.vue";
import Mesures from "./components/Mesures.vue";
import Morse from "./components/Morse.vue";

const routes = [
  { path: "/", component: Home, props: true },
  { path: "/is", component: Iss, props: true },
  { path: "/mesures", component: Mesures },
  { path: "/morse", component: Morse },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
