<template>
  <tr>
    <td>{{mesure}}</td>
    <td>{{ spirale }}km</td>
    <td>{{ v1 }}m</td>
    <td>{{ v2 }}m</td>
  </tr>
</template>

<script>
export default {
  props: ['mesure'],
  computed: {
    spirale() {
      return this.toFixed(this.mesure * 560.606);
    },
    v1() {
      return this.toFixed(this.mesure * 1969.697);
    },
    v2() {
      return this.toFixed(this.mesure * 1959.687);
    }
  },
  methods: {
    toFixed(nb) {
      return nb.toFixed(7)
    }
  }
}
</script>