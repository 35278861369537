<template>
	<div>
		<form action="#" @submit.prevent="codeMorse">
			<p><label for="champMorse">Code Morse (2/4 ou -/.)</label></p>
			<input type="text" :maxlength="max" v-model="morse" />
			{{ morseBinary.length }}
			<p>
				<small>(Longueur max {{ max }})</small>
			</p>
			<p>
				<input type="radio" v-model="format" value="1" id="format1" />
				<label for="format1">2 = trait, 4 = point</label>
			</p>
			<p>
				<input type="radio" v-model="format" value="2" id="format2" />
				<label for="format2">2 = point, 4 = trait</label>
			</p>
			<p>
				<input type="radio" v-model="format" value="3" id="format3" />
				<label for="format3">les deux (long)</label>
			</p>
			<button>OK</button>
			<span v-if="generate"> génération...</span>
		</form>

		<div v-if="decodedReversed.length">
			<p>2 = trait, 4 = point</p>
			<ul>
				<li v-for="(decode, i) in decodedReversed" :key="i">
					{{ decode }}
				</li>
			</ul>
		</div>

		<div v-if="decoded.length">
			<p>2 = point, 4 = trait</p>
			<ul>
				<li v-for="(decode, i) in decoded" :key="i">
					{{ decode }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
let characters = {
	1: {
		// Latin => https://en.wikipedia.org/wiki/Morse_code
		A: "01",
		B: "1000",
		C: "1010",
		D: "100",
		E: "0",
		F: "0010",
		G: "110",
		H: "0000",
		I: "00",
		J: "0111",
		K: "101",
		L: "0100",
		M: "11",
		N: "10",
		O: "111",
		P: "0110",
		Q: "1101",
		R: "010",
		S: "000",
		T: "1",
		U: "001",
		V: "0001",
		W: "011",
		X: "1001",
		Y: "1011",
		Z: "1100",
	},
	2: {
		// Numbers
		0: "11111",
		1: "01111",
		2: "00111",
		3: "00011",
		4: "00001",
		5: "00000",
		6: "10000",
		7: "11000",
		8: "11100",
		9: "11110",
	},
};

export default {
	data() {
		return {
			max: 20,
			format: 1,
			generate: false,
			morse: "",
			decoded: [],
			decodedReversed: [],
		};
	},
	computed: {
		decodedAlpha() {
			let decoded = this.decoded;
			return decoded.sort((a, b) => a > b);
		},
		morseBinary() {
			return [...this.morse]
				.map((m) => (m == "." || m == "2" ? "0" : "1"))
				.join("");
		},
		morseBinaryReversed() {
			return this.morseBinary
				.split("")
				.map((code) => Math.abs(code - 1))
				.join("");
		},
	},
	methods: {
		codeMorse() {
			// first, clear
			this.decoded = [];
			this.decodedReversed = [];

			if (this.morseBinary == "") {
				return;
			}
			this.generate = true;
			setTimeout(() => {
				if (this.format == 1 || this.format == 3) {
					this.decodedReversed = this.start(this.morseBinaryReversed);
				}
				if (this.format == 2 || this.format == 3) {
					this.decoded = this.start(this.morseBinary);
				}
				this.generate = false;
			}, 250);
		},
		next(counts) {
			if (counts.length == 1) {
				return false;
			}
			counts[counts.length - 2]++;
			if (counts[counts.length - 1] == 1) {
				counts.pop();
			} else {
				let ones = counts[counts.length - 1] - 1;
				counts.pop();
				for (let i = 0; i < ones; i++) {
					counts.splice(counts.length + i, 0, 1);
				}
			}
			return true;
		},
		start(morse) {
			let n = morse.length;
			let counts = Array(n).fill(1);
			let allCounts = [];

			do {
				allCounts.push(window.structuredClone(counts));
			} while (this.next(counts));

			let allDecodedMorse = [];

			// allCounts: [[1,1,1],[1,2],[2,1]]
			allCounts.forEach((count) => {
				let i = 0;
				let decodedMorse = [];

				count.forEach((nb) => {
					if (nb == 1) {
						decodedMorse.push(morse[i]);
						i++;
					}
					if (nb > 1) {
						decodedMorse.push(morse.substr(i, nb));
						i += nb;
					}
				});

				// decodedMorse: [1,0,1] or [1,01] or [10,1]
				decodedMorse = decodedMorse.map((morse) => {
					let find = false;
					for (let group in characters) {
						for (let letter in characters[group]) {
							if (characters[group][letter] == morse) {
								find = true;
								return letter;
							}
						}
					}
					if (!find) {
						return "|ERR|";
					}
				});

				allDecodedMorse.push(decodedMorse.join(""));
			});

			allDecodedMorse = allDecodedMorse.filter((morse) => {
				return !morse.includes("|ERR|");
			});

			return allDecodedMorse;
		},
	},
};
</script>
