<template>
  <div>
    <label for="radio1">Ordre officiel</label>
    <input type="radio" v-model="filter" value="officiel" id="radio1" />
    <label for="radio2">Ordre livre</label>
    <input type="radio" v-model="filter" value="livre" id="radio2" />
    <Enigme v-for="enigme of filteredEnigmes" :key="enigme.id" :data="enigme" />
  </div>
</template>

<script>
import Enigme from "./Enigme";

export default {
  components: {
    Enigme,
  },
  props: ["chouette"],
  data() {
    return {
      enigmes: [],
      filter: "officiel",
    };
  },
  computed: {
    filteredEnigmes() {
      let criteria = this.filter === "officiel" ? "id" : "ordre_livre";
      return window.structuredClone(this.enigmes).sort((a, b) => {
        return a[criteria] - b[criteria];
      });
    },
  },
  watch: {
    chouette() {
      this.enigmes = this.chouette[2].data;
    },
  },
  mounted() {
    if (this.chouette[2]) {
      this.enigmes = this.chouette[2].data;
    }
  },
};
</script>
