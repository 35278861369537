<template>
  <div class="Enigme">
    <h2>{{ data.nom }} - {{ data.titre }}</h2>
    <img :src="urlImage" alt="" width="200" />
    <div>
      <p class="ligne" v-for="(ligne, i) of lignes" :key="i">
        {{ ligne }}
      </p>
    </div>

    <!--<h4>Nombre de mots par ligne</h4>
    <div>
      <p class="ligne">Titre: {{ titreNbMots }}</p>
      <p class="ligne" v-for="(ligne, i) of lignesNbMots" :key="i">
        {{ ligne }}
      </p>
    </div>

    <h4>Nombre de lettres par mot, par ligne</h4>
    <div>
      <p class="ligne">Titre: {{ titreNbLettres }}</p>
      <p class="ligne" v-for="(ligne, i) of lignesNbLettres" :key="i">
        {{ ligne }}
      </p>
    </div>

    <h4>Somme des lettres (texte + titre = total)</h4>
    <p>{{ sommeLettresTexte }} + {{ sommeLettresTitre }} = {{ sommeLettresTexte + sommeLettresTitre }}</p>-->
  </div>
</template>

<script>
export default {
  name: "Enigme",
  props: ["data"],
  computed: {
    lignes() {
      return this.data.texte.split("\r\n");
    },
    lignesBrutes() {
      return this.lignes.map((ligne) => {
        ligne = ligne.replaceAll(",", " ");
        ligne = ligne.replaceAll(".", " ");
        ligne = ligne.replaceAll("'", " ");
        ligne = ligne.replaceAll("-", " ");
        ligne = ligne.replaceAll(":", " ");
        ligne = ligne.replaceAll("!", " ");
        // 2 espaces = 1 espace
        ligne = ligne.replaceAll("  ", " ");
        // espace au debut et à la fin
        ligne = ligne.trim();
        return ligne;
      });
    },
    lignesNbMots() {
      return this.lignesBrutes.map((ligne) => {
        return ligne.split(" ").length;
      });
    },
    lignesNbLettres() {
      return this.lignesBrutes.map((ligne) => {
        return ligne
          .split(" ")
          .map((mot) => {
            return mot.length;
          })
          .join(",");
      });
    },
    titreBrut() {
      let titre = this.data.titre;
      titre = titre.replaceAll("\r\n", " ");
      titre = titre.replaceAll(",", " ");
      titre = titre.replaceAll(".", " ");
      titre = titre.replaceAll("'", " ");
      titre = titre.replaceAll("-", " ");
      titre = titre.replaceAll(":", " ");
      titre = titre.replaceAll("!", " ");
      // 2 espaces = 1 espace
      titre = titre.replaceAll("  ", " ");
      // espace au debut et à la fin
      titre = titre.trim();
      return titre;
    },
    titreMots() {
      return this.titreBrut.split(" ");
    },
    titreNbMots() {
      return this.titreMots.length;
    },
    titreNbLettres() {
      return this.titreMots.map((mot) => mot.length).join(",");
    },
    sommeLettresTexte() {
      return this.lignesNbLettres.reduce((total, ligne) => {
        let totalLigne = ligne
          .split(",")
          .map(Number)
          .reduce((acc, i) => acc + i);
        console.log(totalLigne);
        return total + Number(totalLigne);
      }, 0);
    },
    sommeLettresTitre() {
      return this.titreNbLettres
        .split(",")
        .map(Number)
        .reduce((acc, i) => acc + i);
    },
    urlImage() {
      return require("@/assets/images/visuel_" + this.data.nom + ".jpg");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Enigme {
  text-align: center;
  white-space: pre-line;
}
.ligne {
  min-height: 16px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.ligne {
  margin: 0;
}
</style>
