<template>
  <div>
    <h1>IS</h1>
    <Is v-for="is of iss" :key="is.id" :data="is" />
  </div>
</template>

<script>
import Is from "./Is";

export default {
  components: {
    Is,
  },
  props: ["chouette"],
  data() {
    return {
      iss: [],
    };
  },
  mounted() {
    this.iss = this.chouette[3].data;
  },
};
</script>
